import React from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Section from "../components/section";

const CookiesPolicy = () => {
    return <Layout staticHeader>
        <SEO title="Política de Cookies" description=""/>
        
        <Section title="Política de Cookies">
        <div class="container">
          <h3>1. Explicaci&oacute;n del concepto &ldquo;cookie&rdquo;</h3>
          <p>La p&aacute;gina web www.adventurees.com (en adelante, el Sitio Web) utiliza cookies. Las cookies son peque&ntilde;os ficheros de datos que se generan en el ordenador del Usuario y que permiten conocer, a t&iacute;tulo de ejemplo, la siguiente informaci&oacute;n:</p>
          <ul>
            <li>La fecha y la hora de la &uacute;ltima vez que el Usuario visit&oacute; el Sitio Web.</li>
            <li>El dise&ntilde;o de contenidos que el Usuario escogi&oacute; en su &uacute;ltima visita al Sitio Web.</li>
            <li>Elementos de seguridad que intervienen en el control de acceso a las &aacute;reas restringidas.</li>
          </ul>
          <p>Toda esta informaci&oacute;n que permite reconocer al usuario y mejorar el servicio ofrecido.</p>
          <h3>2. Aceptaci&oacute;n de la pol&iacute;tica de cookies</h3>
          <p>Seg&uacute;n el art&iacute;culo 22 de la LSSICE, ADVENTUREES queda obligado a informar previamente y solicitar el consentimiento del Usuario. Por ello ADVENTUREES informa al Usuario que:</p>
          <p>A.- Al registrarse en el Sitio Web est&aacute; dando el consentimiento inequ&iacute;voco para la instalaci&oacute;n de las cookies del Sitio Web en su ordenador.</p>
          <p>B.- En caso de que no est&eacute; registrado, ADVENTUREES entender&aacute; igualmente que el Usuario presta su consentimiento inequ&iacute;voco con tan solo navegar por el Sitio Web. Ello porque durante este proceso se podr&aacute; especificar aquellas caracter&iacute;sticas que desea el Usuario, integrando a las cookies.</p>
          <p>C.- En ning&uacute;n caso la negativa a la instalaci&oacute;n de cookies significar&aacute; que el Usuario no pueda acceder a los servicios, si bien los mismos quedar&aacute;n limitados por no disponer de datos necesarios para la prestaci&oacute;n del servicio de personalizaci&oacute;n.</p>
          <p>D.- El Usuario entiende que las cookies del Sitio Web son gestionadas por</p>
          <p>E.- ADVENTUREES utiliza las cookies para recordar las preferencias que el Usuario ha elegido. Esto tiene dos consecuencias:</p>
          <ol>
            <li>Si elimina todas sus cookies, deber&aacute; actualizar de nuevo sus preferencias respecto a al Sitio Web.</li>
            <li>Si utiliza otro dispositivo, otro perfil de usuario u otro navegador, deber&aacute; indicar de nuevo sus preferencias.</li>
          </ol>
          <h3>3. Cookies que no requieren consentimiento</h3>
          <p>Aquellas que resulten estrictamente necesarias para el funcionamiento del servicio, las de naturaleza t&eacute;cnica y aquellas expresamente autorizadas por el Usuario, como las Cookies de entrada del Usuario, de autentificaci&oacute;n o identificaci&oacute;n del Usuario y de seguridad del Usuario, reproductor multimedia, equilibrar la carga, personalizaci&oacute;n de la interfaz de Usuario y de complemento para intercambiar contenidos sociales.</p>
          <p>El &aacute;mbito de aplicaci&oacute;n de las mismas ser&aacute;:</p>
          <ol>
            <li>Dispositivos de almacenamiento y recuperaci&oacute;n de datos.</li>
            <li>Equipos terminales de los destinatarios, siendo destinatarios: &ldquo;personas f&iacute;sicas o jur&iacute;dicas que utilizan, sea o no por motivos profesionales, un servicio de la sociedad de la informaci&oacute;n&rdquo;.</li>
          </ol>
          <p>Se usan con las siguientes finalidades:</p>
          <ul>
            <li>Recordar cierta informaci&oacute;n del Usuario como, por ejemplo, los datos que haya introducido en los formularios en su navegar por las diferentes p&aacute;ginas de una determinada web en una misma sesi&oacute;n o visita.</li>
            <li>Recordar los productos y servicios al acceder a la p&aacute;gina de pago.</li>
            <li>Identificarse como usuario registrado.</li>
            <li>Garantizar que est&aacute; conectando con el servicio adecuado del Sitio Web cuando realicemos alg&uacute;n cambio en el funcionamiento del mismo.</li>
          </ul>
          <h3>4. Cookies que requieren su consentimiento</h3>
          <h4>4.1. Seg&uacute;n su finalidad</h4>
          <p>A.- Personalizaci&oacute;n: Permiten al Usuario acceder al servicio con algunas caracter&iacute;sticas de car&aacute;cter general predefinidas en funci&oacute;n de una serie de criterios en el terminal del Usuario por ejemplo el idioma o el tipo de navegador a trav&eacute;s del cual accede al servicio.</p>
          <p>Se usan con las siguientes finalidades:</p>
          <ul>
            <li>Recordar determinados ajustes que haya realizado, como la colocaci&oacute;n o las preferencias y colores.</li>
            <li>Recordar si ya se le ha preguntado si desea participar en una encuesta, con la intenci&oacute;n de no volver a molestarle.</li>
            <li>Indicarle cu&aacute;ndo est&aacute; conectado al Sitio Web.</li>
            <li>Compartir informaci&oacute;n con empresas colaboradoras o proveedoras de servicios que tengan conexi&oacute;n con el desarrollo del modelo de negocio y la operatividad del Sitio Web. La informaci&oacute;n compartida ser&aacute; utilizada &uacute;nicamente para prestar el servicio o la operatividad del Sitio Web.</li>
          </ul>
          <p>En determinadas ocasiones, algunas de estas cookies son gestionadas por terceros pero en ning&uacute;n caso permitimos que se utilicen para otro prop&oacute;sito que no sea alguno de los indicados en el apartado anterior. Los Usuarios pueden decidir si permiten o no el uso de estas cookies.</p>
          <p>B.- An&aacute;lisis: Permiten el seguimiento y an&aacute;lisis de la actividad de los Usuarios en los sitios web a los que est&aacute;n vinculadas. La informaci&oacute;n recogida se utiliza, por ejemplo en la medici&oacute;n de la actividad de los sitios web o en la elaboraci&oacute;n de perfiles.</p>
          <p>Se usan con las siguientes finalidades:</p>
          <ul>
            <li>Obtener estad&iacute;sticas sobre c&oacute;mo se utiliza el Sitio Web.</li>
            <li>Comprobar la efectividad de los anuncios publicitarios.</li>
            <li>Realizar mejoras en el Sitio Web mediante el an&aacute;lisis de los posibles errores que se produzcan.</li>
            <li>Probar diferentes dise&ntilde;os para el Sitio Web.</li>
          </ul>
          <strong>No obstante, ADVENTUREES le informa que:</strong>
          <p>Las cookies de an&aacute;lisis son de Google Analytics: Las mismas permiten medir y analizar el tr&aacute;fico de la web. Cookies: __utma, __utmb, __utmc, __utmz. Google Analytics es un servicio anal&iacute;tico de web prestado por Google, Inc. con domicilio en los Estados Unidos con sede central en 1600 Amphitheatre Parkway, Mountain View, California 94043. Para la prestaci&oacute;n de estos servicios, estos utilizan cookies que recopilan la informaci&oacute;n, incluida la direcci&oacute;n IP del Usuario, que ser&aacute; transmitida, tratada y almacenada por Google en los t&eacute;rminos fijados en la Web Google.com. Incluyendo la posible transmisi&oacute;n de dicha informaci&oacute;n a terceros por razones de exigencia legal o cuando dichos terceros procesen la informaci&oacute;n por cuenta de Google.</p>
          <p><strong>NOTA: </strong>Salvo que el Usuario decida registrarse, la cookie nunca ir&aacute; asociada a ning&uacute;ndato de car&aacute;cter personal que pueda identificarle. Dichas cookies s&oacute;lo ser&aacute;n utilizadas con prop&oacute;sitos estad&iacute;sticos que ayuden a la optimizaci&oacute;n de la experiencia de los Usuarios en el Sitio Web.</p>
          <p>M&aacute;s informaci&oacute;n sobre la pol&iacute;tica de privacidad de estas herramientas de anal&iacute;tica en Google Analytics ( <a target="blank" href="https://www.google.es/intl/es_ALL/analytics/learn/privacy.html">https://www.google.es/intl/es_ALL/analytics/learn/privacy.html</a> ).</p>
          <h4>4.2. Seg&uacute;n plazo de tiempo</h4>
          <p>A.- Cookies de sesi&oacute;n (temporales): Son un tipo de cookies dise&ntilde;adas para recabar y almacenar datos mientras el Usuario accede al Sitio Web. Se suelen emplear para almacenar informaci&oacute;n que solo interesa conservar para la prestaci&oacute;n del servicio solicitado por el Usuario en una sola ocasi&oacute;n (por ejemplo, una lista de productos adquiridos).</p>
          <p>B.- Cookies persistentes: Son un tipo de cookies en que los datos siguen almacenadas en el terminal del Usuario y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios a&ntilde;os. La fecha de caducidad de cada cookie ser&aacute; definida individualmente y podr&aacute; consultarse en la informaci&oacute;n aneja a dicha cookie.</p>
          <h3>5. Protecci&oacute;n de datos</h3>
          <p>En caso de tratar cookies que caigan bajo la legislaci&oacute;n de protecci&oacute;n de datos personales, ADVENTUREES se asegura de dar cumplimiento a las exigencias establecidas en la Ley Org&aacute;nica 15/1999, de 13 de diciembre, de Protecci&oacute;n de Datos de Car&aacute;cter Personal, y en su Reglamento de Desarrollo, aprobado por el Real Decreto 1720/2007, de 21 de diciembre. Asimismo, tomar&aacute; las medidas de seguridad pertinentes para garantizar la protecci&oacute;n de los datos personales del Usuario, de acuerdo a la mencionada legislaci&oacute;n vigente.</p>
          <h3>6. Modo privado de navegaci&oacute;n</h3>
          <p>En muchos navegadores se puede activar un modo privado de navegaci&oacute;n mediante el cual las cookies se borran siempre despu&eacute;s de navegar por el Sitio Web. Dependiendo de cada navegador este modo privado puede tener distintas denominaciones. En el p&aacute;rrafo siguiente se proceder&aacute; a dar una lista de los navegadores m&aacute;s comunes y los diferentes nombres de este &ldquo;modo privado&rdquo;:</p>
          <ul>
            <li>Internet Explorer 8 y superior: InPrivate</li>
            <li>Safari 2 y superior: Navegaci&oacute;n Privada</li>
            <li>Firefox 3.5 y superior: Navegaci&oacute;n Privada</li>
            <li>Google Chrome 10 y superior: Inc&oacute;gnito</li>
            <li>Opera 10.5 y superior: Navegaci&oacute;n Privada</li>
          </ul>
          
          <div>
            <h3>7. Revocaci&oacute;n y eliminaci&oacute;n de cookies</h3>
            <p>
              Los Usuarios pueden permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuraci&oacute;n de las 
              opciones del navegador instalado en su ordenador. Para conocer la forma en la que configurar la gesti&oacute;n de cookies en 
              su navegador puede consultar los siguientes links de los principales navegadores y dispositivos:
            </p>
            <ul>
              <li>
                Para Internet Explorer&trade;: 
                <a target="_blank" href="http://windows.microsoft.com/es-ES/windows-vista/Block-or-allow-cookies" rel="noopener noreferrer">
                  http://windows.microsoft.com/es-ES/windows-vista/Block-or-allow-cookies
                </a>
              </li>
              <li>
                Para Safari&trade;: 
                <a target="_blank" href="http://support.apple.com/kb/HT1677?viewlocale=es_ES" rel="noopener noreferrer">
                  http://support.apple.com/kb/HT1677?viewlocale=es_ES
                </a>
              </li>
              <li>
                Para Firefox&trade;: 
                <a target="_blank" href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" rel="noopener noreferrer">
                  http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
                </a>
              </li>
              <li>
                Para Chrome&trade;: 
                <a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=es&hlrm=en" rel="noopener noreferrer">
                  https://support.google.com/chrome/answer/95647?hl=es&amp;hlrm=en
                </a>
              </li>
              <li>
                Para Opera&trade;: 
                <a target="_blank" href="http://help.opera.com/Windows/11.50/es-ES/cookies.html" rel="noopener noreferrer">
                  http://help.opera.com/Windows/11.50/es-ES/cookies.html
                </a>
              </li>
              <li>
                Para Android&trade;: 
                <a target="_blank" href="https://support.google.com/android/?hl=es" rel="noopener noreferrer">
                  https://support.google.com/android/?hl=es
                </a>
              </li>
              <li>
                Para Windows Phone&trade;: 
                <a target="_blank" href="http://www.windowsphone.com/es-es/how-to/wp7/web/changing-privacy-and-other-browser-settings" rel="noopener noreferrer">
                  http://www.windowsphone.com/es-es/how-to/wp7/web/changing-privacy-and-other-browser-settings
                </a>
              </li>
              <li>
                Para Safari en IOS (iPhone i iPad): 
                <a target="_blank" href="http://support.apple.com/kb/ht1677?viewlocale=es_es&locale=es_es" rel="noopener noreferrer">
                  http://support.apple.com/kb/ht1677?viewlocale=es_es&amp;locale=es_es
                </a>
              </li>
              <li>
                Para Chrome en Android: 
                <a target="_blank" href="https://support.google.com/chrome/answer/2392971?hl=es" rel="noopener noreferrer">
                  https://support.google.com/chrome/answer/2392971?hl=es
                </a>
              </li>
              <li>
                Para Firefox en Android: 
                <a target="_blank" href="http://support.mozilla.org/es/kb/como-eliminar-las-cookies-los-datos-privados-el-hi" rel="noopener noreferrer">
                  http://support.mozilla.org/es/kb/como-eliminar-las-cookies-los-datos-privados-el-hi
                </a>
              </li>
            </ul>
            <p>
              Si tiene dudas sobre esta Pol&iacute;tica de Cookies, puede contactar con ADVENTUREES en info@adventurees.com
            </p>
          </div>
        </div>
        </Section>
    </Layout>
}

export default CookiesPolicy;
